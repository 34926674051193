<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button class="btn btn-primary saveButton" @click="save">{{ $t('Save Order') }}</button>
    </div>
    <div class="form-inner">
      <div class="order-infomration">
        <el-card class="box-card order-status-card" v-if="editMode">
          <div slot="header" class="clearfix">
            <span>{{ $t('Order Status') }}</span>
          </div>
          <el-form class="order-status-form" ref="order-status-form" :disabled="orderLocked" :model="form"
            label-width="150px" label-position="left">
            <div class="row" style="width: 80%">
              <el-form-item :label="$t('Order Status')" style="width: 100%">
                <el-select :disabled="orderLocked" v-model="form.orderStatus">
                  <el-option :label="$t('Unpaid')" value="Unpaid">
                  </el-option>
                  <el-option :label="$t('Unfulfilled')" value="Unfulfilled">
                  </el-option>
                  <el-option :label="$t('Completed')" value="Completed">
                  </el-option>
                  <el-option :label="$t('Cancelled')" value="Cancelled">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </el-card>

        <el-dialog :title="$t('Select Customer')" :visible.sync="displayCustomerForm" width="60%">
          <SelectCustomer @customerSelected="updateCustomer" />
        </el-dialog>

        <el-card class="box-card related-customer-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('Customer information') }}</span>
          </div>
          <el-form v-if="!editMode" class="related-customer-form" ref="related-customer-form" :model="form"
            label-width="150px" label-position="left">
            <div class="row">
              <el-button @click="activeSelectCustomer"> {{ $t('Select Customer') }} </el-button>
              <el-button @click="activeCreateCustomer"> {{ $t('Create Customer') }} </el-button>
            </div>
            <div class="row create-customer-form" v-if="createCustomer || form.customer">
              <div class="row" style="width: 80%">
                <el-form-item :label="$t('Name')" style="max-width: 80%">
                  <el-input :disabled="selectCustomer" v-model="form.customer.name"></el-input>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item class="email" :label="$t('Email')" style="max-width: 80%">
                  <el-input :disabled="selectCustomer" v-model="form.customer.email"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Phone Number')" style="max-width: 80%">
                  <el-input :disabled="selectCustomer" v-model="form.customer.phone"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <el-form v-if="editMode && form.customer" class="related-customer-form" ref="related-customer-form"
            :model="form" label-width="150px" label-position="left">
            <div class="row create-customer-form">
              <div class="row" style="width: 80%">
                <el-form-item :label="$t('Name')" style="width: 100%">
                  <el-input :disabled="true" v-model="form.customer.name"></el-input>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item class="email" :label="$t('Email')" style="max-width: 80%">
                  <el-input :disabled="true" v-model="form.customer.email"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Phone Number')" style="max-width: 80%">
                  <el-input :disabled="true" v-model="form.customer.phone"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </el-card>

        <el-card class="box-card related-service-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('Select Service') }}</span>
          </div>
          <el-form class="related-service-form" ref="related-service-form" :model="form" label-width="150px"
            label-position="left">
            <div class="row" style="width: 80%">
              <el-form-item :label="$t('Services')" style="max-width: 80%">
                <el-select :disabled="editMode" v-if="langcode === 'en'" v-model="form.services" filterable multiple
                  :placeholder="$t('Please Select')" @change="updateTotalAmount">
                  <el-option v-for="service in services" :key="service.id" :label="service.service_name_en"
                    :value="service.id">
                  </el-option>
                </el-select>
                <el-select :disabled="editMode" v-else v-model="form.relatedServices" filterable multiple
                  :placeholder="$t('Please Select')" @change="updateTotalAmount">
                  <el-option v-for="service in services" :key="service.id" :label="service.service_name_zh"
                    :value="service.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('Booking Datetime')" style="max-width: 80%">
                <el-date-picker v-model="form.bookingDatetime" type="datetime">
                </el-date-picker>
              </el-form-item>
            </div>
          </el-form>
        </el-card>

        <el-card class="box-card remark-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('Order Remark') }}</span>
          </div>
          <el-input type="textarea" class="textarea" :disabled="orderLocked" v-model="form.remark"></el-input>
        </el-card>
      </div>

      <div class="order-amount">
        <el-card class="box-card related-order-amount-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('Order Amount') }}</span>
          </div>
          <div class="amount-content">
            {{ $t('Total Amount') }}: HK${{ totalAmount }}
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Service from '@/lib/service';
import SelectCustomer from '@/components/order/SelectCustomer';

export default {
  name: 'OrderForm',
  components: {
    SelectCustomer,
  },
  props: {
    'editMode': {
      type: Boolean,
      default: false,
    },
  },
  data() {
    //Default form value
    let form = {
      customerId: null,
      customer: null,
      orderStatus: '',
      relatedServices: [],
      remark: '',
      bookingDatetime: '',
    };
    return {
      form,
      services: [],
      products: [],
      displayCustomerForm: false,
      selectCustomer: false,
      createCustomer: false,
      selectedProducts: [],
      currentOption: '',
      totalAmount: 0,
      orderLocked: false,
    };
  },
  mounted() {
    this.loadServices();
  },
  methods: {
    async loadServices() {
      try {
        const loginInfo = Common.getLoginInfo();
        const services = await Service.loadAllServices(this.apiUrl, loginInfo);
        this.services = services;
      } catch (err) {
        console.log(err);
      }
    },
    getTotalAmount() {
      let totalAmount = 0;
      const selectServices = this.form.relatedServices;
      //Check selected services amount
      for (let i = 0; i < selectServices.length; i++) {
        const serviceId = selectServices[i];
        const service = this.services.find(function (item) {
          return item.id === serviceId;
        });
        const servicePrice = Number(service.service_price);
        totalAmount += servicePrice;
      }
      return totalAmount;
    },
    updateTotalAmount() {
      const totalAmount = this.getTotalAmount();
      this.totalAmount = totalAmount;
    },
    updateCustomer(customer) {
      this.form.customer = {
        'name': customer.customer_name,
        'email': customer.customer_email,
        'phone': customer.customer_phone,
      };
      this.form.customerId = customer.id;
      this.displayCustomerForm = false;
    },
    activeSelectCustomer() {
      //Clear the form customer first
      this.form.customer = null;
      this.displayCustomerForm = true;
      this.createCustomer = false;
      this.selectCustomer = true;
    },
    activeCreateCustomer() {
      this.form.customer = {
        'name': '',
        'email': '',
        'phone': '',
      };
      this.selectCustomer = false;
      this.createCustomer = true;
    },
    async save() {
      if (!this.form.customerId) {
        if (!this.form.customer.name || this.form.customer.name.length === 0) {
          this.$message.error(this.$t('You should input the customer name.'));
          return;
        }
      }
      this.$emit('action', this.form);
    },
  },
  watch: {
    'currentOrder': function () {
      const customer = this.currentOrder.related_customer;
      let bookingDatetime = dayjs.unix(this.currentOrder.booking_datetime);
      bookingDatetime = bookingDatetime.toISOString();

      this.form = {
        customer: {
          id: customer.id,
          name: customer.customer_name,
          email: customer.customer_email,
          phone: customer.customer_phone,
        },
        orderStatus: this.currentOrder.order_status,
        customerId: customer.id,
        remark: this.currentOrder.order_remark,
        relatedServices: JSON.parse(this.currentOrder.related_service),
        bookingDatetime,
      };
      
      if (this.currentOrder.order_status === 'Completed' || this.currentOrder.order_status === 'Cancelled') {
        this.orderLocked = true;
      }
      this.totalAmount = this.getTotalAmount();
    },
  },
  computed: mapState({
    currentOrder: state => state.order.currentOrder,
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.order {
  .form-inner {
    display: flex;
  }
  .el-date-editor .el-range-separator{
      width: 6%;
  }

  .action-button-wrapper {
    text-align: right;
    margin-bottom: 50px;
  }

  .form-wrapper {
    padding-left: 20px;
  }

  .order-status-card {
    margin-bottom: 50px;
  }

  .related-customer-form,
  .related-delivery-form,
  .order-status-form,
  .payment-form {
    padding-left: 20px;
  }

  .create-customer-form {
    margin-top: 50px;
    padding-left: 20px;

    .email {
      margin-right: 50px;
    }
  }

  .el-card__header {
    background-color: black;
    color: white;
  }

  .related-service-card {
    margin-top: 50px;
  }

  .related-service-form {
    padding-left: 20px;
  }

  .option-number {
    margin-top: 30px;
  }

  .selected-products-list {
    margin-top: 50px;

    .selected-product {
      display: flex;
      margin-bottom: 30px;
    }

    .product-image-wrapper {
      width: 110px;
      height: 110px;
      position: relative;
      overflow: hidden;

      .product-image {
        overflow: hidden;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .product-info {
      margin-right: 30px;
    }
  }

  .remark-card {
    margin-top: 50px;

    .el-textarea__inner {
      height: 100px !important;
    }
  }


  .order-infomration {
    min-width: 700px;
    margin-right: 50px;
  }

  .order-amount {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
  }

  .product-amount {
    margin-top: 20px;
  }
}
</style>